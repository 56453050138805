.black-overlay-right {
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0);
	background: -moz-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
	background: -webkit-gradient(left top, right top, color-stop(0%, rgba(0,0,0,0)), color-stop(100%, rgba(0,0,0,0.5)));
	background: -webkit-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
	background: -o-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
	background: -ms-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
	background: linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=1 );
	background-blend-mode: multiply;
}

.black-overlay-left {
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0);
	background: -moz-linear-gradient(left, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);
	background: -webkit-gradient(left top, right top, color-stop(0%, rgba(0,0,0,0.5)), color-stop(100%, rgba(0,0,0,0)));
	background: -webkit-linear-gradient(left, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);
	background: -o-linear-gradient(left, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);
	background: -ms-linear-gradient(left, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);
	background: linear-gradient(to right, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=1 );
	background-blend-mode: multiply;
}



.landing {
	h2 {
		color: @white;
		margin-top: 0;
		margin-bottom: 20px;
	}
	h5 {
		color: @white;
		margin-bottom: 20px;
	}
}

.landing1 {
	background-image: url(../images/landing1.jpg);
	background-repeat: no-repeat;
	background-position: center bottom;
	background-size: cover;
	background-color: rgba(0,0,0,0.6);
	background-blend-mode: multiply;
}

.landing2 {
	background-image: url(../images/landing2.jpg);
	background-repeat: no-repeat;
	background-position: top left -370px;
	background-size: cover;
	.youtube-video {
		width: 100%;
		-webkit-box-shadow: 5px 5px 15px 0px rgba(0,0,0,0.5);
		-moz-box-shadow: 5px 5px 15px 0px rgba(0,0,0,0.5);
		box-shadow: 5px 5px 15px 0px rgba(0,0,0,0.5);
	}
	.btn {
		margin-bottom: 30px;
	}
}

.landing3 {
	background-image: url(../images/landing3.jpg);
	background-repeat: no-repeat;
	background-position: top center;
	background-size: cover;
	background-color: @red;
	background-blend-mode: multiply;
	.owl-carousel { 
		.item {
			text-align: center;
			background-color: transparent;
			img {
				display: inline;
				width: 150px;
				height: 150px;
				border-radius: 100px;
				border: solid 5px @white;
				margin-bottom: 30px;
			}
			.item-content {
				padding: 10px 15px;
				overflow: hidden;
				color: @white;
				h5 {
					margin: 0;
				}
				p {
					text-align: center;
				}
				
				.border-bottom {
					width: 100px;
					margin: 15px auto;
					border-bottom: solid 3px #fff;
				}
			}
		}
		.item:hover {
			opacity: 0.8;
			filter: alpha(opacity=80);
		}
	}
	.owl-nav {
	}
	.owl-prev, .owl-next {
		width: 51px;
		height: 51px;
		position: absolute;
		top: 30%;
	}
	.owl-prev {
		background-image: url(../images/previous-button.png);
		background-repeat: no-repeat;
		left: 0px;
	}
	.owl-next {
		background-image: url(../images/next-button.png);
		background-repeat: no-repeat;
		right: 0px;
	}
	.owl-prev:hover, owl-next:hover {
		opacity: 0.8;
		filter: alpha(opacity=80);
	}
}





/* Small devices (tablets, 768px and up) */
@media (min-width: @screen-sm-min) {
	.landing {
		h2 {
			margin-bottom: 40px;
		}
		h5 {
			margin-bottom: 40px;
		}
	}
	.landing1 {
		background-position: left bottom;
		background-color: transparent;
	}
	.landing2 {
		background-position: top center;
		.btn {
			margin-bottom: auto;
		}
	}
	.landing3 {
		.owl-prev {
			left: -60px;
		}
		.owl-next {
			right: -60px;
		}
	}
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: @screen-md-min) {
	.black-overlay-left {
		min-height: 550px;
	}
}
/* Large devices (large desktops, 1200px and up) */
@media (min-width: @screen-lg-min) {
	
}