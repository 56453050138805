.header-banner {
	width: 100%;
	min-height: 115px;
	position: relative;
	margin-top: 8px;
	padding: 15px 0;
	z-index: 1;
	background-size: cover;
	-webkit-clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);
	clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);
	h1 {
		font-size: 24px;
		line-height: 36px;
		color: @white;
		font-weight: 300;
		text-transform: uppercase;
		margin: 0;
	}
}


/* Small devices (tablets, 768px and up) */
@media (min-width: @screen-sm-min) {
	.header-banner {
		min-height: inherit;
		padding: 20px 0;
		h1 {
			font-size: 36px;
			line-height: 48px;
		}
	}
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: @screen-md-min) {
	.header-banner {
	}
}
/* Large devices (large desktops, 1200px and up) */
@media (min-width: @screen-lg-min) {
	.header-banner {
	}
}

@media (min-width: 1600px) {
	
}


