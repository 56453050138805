
/* @form header 
----------------------------------- */
.smart-forms .header-black {	
	background-color:#505558;
	border-top:1px solid #7E8689;  
	border-bottom:5px solid #333333;
}

.smart-forms .header-lite{ border-top-color:#7E8689; }
.smart-forms .header-lite:before{ background-color:#505558;   }
.smart-forms .header-black h4{ color:#fff; }

/* @form tagline 
--------------------------------- */
.smart-forms .theme-black .tagline span{ color:#505558; }
.smart-forms .theme-black .smart-link{ color:#505558; }

/* @hover | focus  
--------------------------------- */
.smart-forms .theme-black .gui-input:hover,
.smart-forms .theme-black .gui-textarea:hover,
.smart-forms .theme-black .select > select:hover,
.smart-forms .theme-black .select-multiple select:hover, 
.smart-forms .theme-black .gui-input:hover ~ .input-hint,
.smart-forms .theme-black .file .gui-file:hover + .gui-input,
.smart-forms .theme-black .gui-textarea:hover ~ .input-hint{ border-color: #6B7376; }

.smart-forms .theme-black .gui-input:focus,
.smart-forms .theme-black .gui-textarea:focus,
.smart-forms .theme-black .select > select:focus,
.smart-forms .theme-black .select-multiple select:focus{ 
	border-color: #505558;
	-webkit-box-shadow:0px 0px 3px #888888 inset;  
	-moz-box-shadow:0px 0px 3px #888888 inset;   
	-o-box-shadow:0px 0px 3px #888888 inset;  	
	box-shadow:0px 0px 3px  #888888 inset;  
	color: #3C3C3C; 
}

.smart-forms .theme-black .gui-textarea:focus{  height: 120px; }
.smart-forms .theme-black .select > select:focus { z-index:10; z-index:20\9; }
.smart-forms .theme-black .gui-input:focus ~ .field-icon i,
.smart-forms .theme-black .gui-textarea:focus ~ .field-icon i{ color:#505558; }
.smart-forms .theme-black .select-multiple select:focus, 
.smart-forms .theme-black .gui-input:focus ~ .input-hint,
.smart-forms .theme-black .gui-textarea:focus ~ .input-hint, 
.smart-forms .theme-black .file .gui-file:focus + .gui-input{ border-color: #505558; }
.smart-forms .theme-black .select > select:focus + .arrow{ color:#505558; }

/* @star rating 
--------------------------------------------------------------- */
.smart-forms .theme-black .rating:hover .rating-star:hover,
.smart-forms .theme-black .rating:hover .rating-star:hover ~ .rating-star,
.smart-forms .theme-black .rating-input:checked ~ .rating-star { color: #333333;	}
.smart-forms .theme-black .rating-star, 
.smart-forms .theme-black .rating:hover .rating-star {	color: #A2A6A8;		 }

/* @check + radio black 
---------------------------------------------------------------- */
.smart-forms .option-black input:hover + .checkbox,  
.smart-forms .option-black input:hover + .radio{ border-color: #6B7376; }
.smart-forms .option-black input:checked + .checkbox, 
.smart-forms .option-black input:focus + .checkbox, 
.smart-forms .option-black input:checked + .radio, 
.smart-forms .option-black input:focus + .radio{ border-color: #505558; }
.smart-forms .option-black input:checked + .radio:before, 
.smart-forms .option-black input:focus + .radio:before{  background: #505558; }
.smart-forms .option-black input:checked + .checkbox:before, 
.smart-forms .option-black input:focus + .checkbox:before{ border-color: #505558; }

/* @toggle-switch black 
------------------------------------------------------------ */
.smart-forms .switch-black > input:checked + .switch-label { background: #505558; border-color: #505558; }
.smart-forms .switch-black > input:checked + .switch-label:after { color:#505558; }
.smart-forms .switch-black > input:checked:focus + .switch-label { background: #333333; border-color: #333333; }

/* @button black 
------------------------------------------------------------- */
.smart-forms .btn-black { background-color: #505558; }
.smart-forms .btn-black:hover,
.smart-forms .btn-black:focus{ background-color: #6B7376; }
.smart-forms .btn-black:active{ background-color: #333333; }
.smart-forms .btn-black,
.smart-forms .btn-black:hover,
.smart-forms .btn-black:focus,
.smart-forms .btn-black:active{ color: #fff; text-shadow: 0 1px rgba(0, 0, 0, 0.08); }

/* @datepicker overides 
-------------------------------------------------- */
.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next,
.ui-datepicker .ui-datepicker-title { color:#505558; }
.ui-datepicker-today a, 
.ui-datepicker-today a:hover, 
.ui-datepicker .ui-state-highlight { background:#505558!important; }

/* @price-box 
------------------------------------------------------------- */
.smart-forms .theme-black .selected-box h4{ color:#505558; }
.smart-forms .ribbon-inner{ background:#505558; }
.smart-forms .ribbon-inner:before,
.smart-forms .ribbon-inner:after{ border-top-color:#333333; }