.header-bar-black {
	border-top: solid 5px #253E8D;
	background-color: @white;
	position: relative;
	z-index: 1;
	width: 100%;
	min-height: 45px;
	.site-logo {
		text-align: center;
		padding: 15px 0;
		img {
			height: auto;
		}
	}
	.header-module {
		ul {
			margin-top: 0px;
			li {
				float: left;
				list-style: none;
				margin-right: 20px;
				padding-right: 20px;
				ul {
					li {
						padding: 12px 0px;
						.fa {
							margin-right: 5px;
						}
						a:link, a:active, a:visited {
							color: @black;
							text-decoration: none;
						}
						a:hover {
							color: lighten(@black,15%);
							text-decoration: none;
						}
					}
				}
			}
		}
	}
}


.search-container{
	float: right;
  	width: 200px;
	height: 35px;
	margin-top: 10px;
}

input#search-bar{
	margin: 0 auto;
	width: 100%;
	height: 35px;
	padding: 0 10px;
	border: 1px solid #D0CFCE;
	outline: none;
	&:focus{
	border: 1px solid #008ABF;
	transition: 0.35s ease;
	color: #008ABF;
	&::-webkit-input-placeholder{
	  transition: opacity 0.45s ease; 
	  opacity: 0;
	 }
	&::-moz-placeholder {
	  transition: opacity 0.45s ease; 
	  opacity: 0;
	 }
	&:-ms-placeholder {
	 transition: opacity 0.45s ease; 
	 opacity: 0;
	 }    
	}
 }

.search-icon{
  	position: relative;
    float: right;
    width: 50px;
    height: 50px;
    top: -43px;
    right: -5px
}


/* Small devices (tablets, 768px and up) */
@media (min-width: @screen-sm-min) {
	.header-bar-black {
		border-top: none;
		border-bottom: solid 1px @black;
		min-height: inherit;
		.site-logo {
			text-align: center;
			padding: 15px 0;
			img {
				height: 110px;
			}
		}
		.header-module {
			p {
				margin-top: 5px;
				margin-bottom: 0;
			}
			ul {
				float: right;
				margin-top: 0;
				li {
					float: left;
					list-style: none;
					margin-left: 20px;
					margin-right: 0;
					padding: 10px 0px;
					font-size: 13px;
					ul {
						li {
							padding-right: 0;
							.fa {
								margin-right: 5px;
							}
							a:link, a:active, a:visited {
								color: @black;
								text-decoration: none;
							}
							a:hover {
								color: lighten(@black,15%);
								text-decoration: none;
							}
						}
					}
				}
			}
		}
	}
	.site-header {
		display: none;
		position: fixed;
		.site-logo {
			margin: 15px 0px;
			text-align: left;
			float: left;
			img {
				height: 61px;
			}
		}
		.header-module {
			margin-top: 33px;
			ul {
				float: right;
				li {
					float: left;
					list-style: none;
					margin-right: 20px;
					padding-right: 20px;
					border-right: solid 1px @grey;
					ul {
						li, li:nth-child(2) {
							border-right: none;
							padding-right: 0;
							.fa {
								margin-right: 5px;
							}
							a:link, a:active, a:visited {
								color: @grey;
								text-decoration: none;
							}
							a:hover {
								color: darken(@grey,15%);
								text-decoration: none;
							}
						}
					}
				}
				li:nth-child(2) {
					a:link, a:active, a:visited {
						color: @blue;
					}
					a:focus {
						color: @grey !important;
					}
				}
				li:last-child {
					border-right: none;
					margin-right: 0;
					padding-right: 0;
					>.fa {
						color: @blue;
						margin-right: 5px;
					}
					a:link, a:active, a:visited {
						color: @grey;
						text-decoration: none;
					}
					a:hover {
						color: darken(@grey,15%);
						text-decoration: none;
					}
				}
			}
		}
	}
	.site-header-white {
		padding: 15px 0 0 0;
    	width: 100%;
		z-index: 1;
		.site-logo {
			margin: 0;
			text-align: left;
			img {
			}
		}
		.header-menu-container {
			margin-top: 4px;
			position: relative;
			z-index: 100;
			.site-nav {
				float: right;
				ul {
					li {
						.fa {
							margin-left: 10px;
						}
					}
				}
			}
		}
		.search {
			width: 100%;
		  	position: relative;
			padding: 0 30px 0 15px;
		  	.searchTerm {	
		  		float: left;
			  	width: 100%;
			  	border: none;
				background-color: #e6e7e8;
			  	padding: 0 50px 0 20px;
			  	height: 36px;
			  	border-top-left-radius: 50px;
				border-top-right-radius: 0px;
				border-bottom-right-radius: 0px;
				border-bottom-left-radius: 50px;
			  	outline: none;
			  	color: #9DBFAF;
			}
			
			.searchTerm:focus{
			  	color: #00B4CC;
			}
			
			.searchButton {
			  	position: absolute;  
			  	right: 15px;
				width: 60px;
				height: 36px;
				border: none;
				background-color: @blue;
				text-align: center;
				color: #fff;
				border-top-left-radius: 0px;
				border-top-right-radius: 50px;
				border-bottom-right-radius: 50px;
				border-bottom-left-radius: 0px;
			  	cursor: pointer;
			  	font-size: 15px;
			}
		}
		.home-category-container {
			padding-left: 0;
			.home-category {
				background-color: @blue;
				-webkit-border-top-left-radius: 5px;
				-webkit-border-top-right-radius: 5px;
				-moz-border-radius-topleft: 5px;
				-moz-border-radius-topright: 5px;
				border-top-left-radius: 5px;
				border-top-right-radius: 5px;
				color: @white;
				font-size: 14px;
				font-family: @mainFont;
				text-align: center;
				padding: 20px;
				.fa {
					margin-left: 10px;
				}
			}
		}
	}
	.menu-bar {
		.header-menu-container {
			margin-top: 0px;
		}
	}
	
	.search-container{
		margin-top: 7px;
	}
	
	.home-category2 {
		button {
			width: 100%;
			border: none;
			background-color: @blue;
			-webkit-border-radius: 5px;
			-moz-border-radius: 5px;
			border-top: 5px;
			color: @white;
			font-size: 14px;
			font-family: @mainFont;
			text-align: center;
			padding: 9px 0;
			.fa {
				margin-left: 10px;
			}
		}
		.dropdown-menu {
			min-width: 100%;
		}
	}
}



/* Medium devices (desktops, 992px and up) */
@media (min-width: @screen-md-min) {
	.header-bar-black {
		.header-module {
			ul {
				li {
					font-size: 14px;
					padding: 8px 0px;
				}
			}
		}
	}
}
/* Large devices (large desktops, 1200px and up) */
@media (min-width: @screen-lg-min) {
}