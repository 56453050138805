/* LESS Document */
@fullWidth : 100%;

/* COLORS */
@white: #fff;
@black: #000;
@red: #C41F38;
@orange: #D96A2E;
@lightGrey: #E8E8E7;
@grey: #8C8884;
@darkGrey: #353535;
@blue: #244897;

@bodyColor : #fff;

/* FONT */

@mainFont : 'Open Sans', sans-serif;
@mainFont-bold : 'Open Sans', sans-serif;

/* TYPOGRAPHY */
@font-size-base:          15px;

@font-size-h1:            floor((@font-size-base * 3.2)); // ~48px
@font-size-h2:            floor((@font-size-base * 2.4)); // ~36px
@font-size-h3:            ceil((@font-size-base * 1.85)); // ~28px
@font-size-h4:            ceil((@font-size-base * 1.7)); // ~24px
@font-size-h5:            ceil((@font-size-base * 1.4)); // ~21px
@font-size-h6:            ceil((@font-size-base * 1.2)); // ~18px

@line-height-base:        1.4; // 20/14
@line-height-computed:    floor((@font-size-base * @line-height-base)); // ~20px


/* FlexSlider */
@font-path: "../fonts";


