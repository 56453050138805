footer {
	width: 100%;
	bottom: 0;
	padding-top: 0px;
	background-color: @white;
	.footer-menu {
		background-color: #d1d2d4;
		padding: 10px 0;
		ul {
			li {
				list-style: none;
				float: left;
				border-right: solid 1px #a7a9ab;
				margin-right: 15px;
				padding-right: 15px;
				a:link, a:active, a:visited {
					color: #231f20;
				}
				a:hover {
					color: #231f20;
					text-decoration: underline;
				}
			}
			li:last-child {
				border-right: none;
			}
		}
	}
	.footer-content {
		color: #404041;
		background-color: #e6e7e8;
		padding: 30px 0 15px 0;
		h6 {
			font-weight: bold;
		}
		p {
			font-size: 13px;
			line-height: 21px;
			margin-bottom: 10px;
		}
		p span {
			font-size: 15px;
			font-weight: bold;
		}
		a:link, a:active, a:visited {
			color: #404041;
		}
		a:hover {
			color: #404041;
			text-decoration: underline;
		}
		.copyright {
			margin-top: 15px;
			border-top: solid 1px #a7a9ab;
			padding-top: 15px;
			font-size: 13px;
			line-height: 21px;
		}
	}
}
.clear-footer {
	height: 0px;
}
.footer-mobile {
	background-color: #d1d2d4;
	.copyright {
		padding: 10px 15px;
		p {
			font-size: 13px;
			text-align: left;
			margin-bottom: 0;
			a:link, a:active, a:visited, a:hover {
				color: darken(@white, 50%);
				text-decoration: underline;
			}
		}
		color: #404041;
	}
	.footer-item {
	}
	.footer-item-head {
		position: relative;
		cursor: pointer;
		border-bottom: solid 1px darken(#d1d2d4, 15%);
		h6 {
			color: #404041;
			font-size: 14px;
			text-transform: uppercase;
			margin: 0px;
			padding: 10px 15px;
			font-family: @mainFont;
		}
		h6:after {
			content: "";
			float: right;
			background: url("../images/caret_down_mobile.png") no-repeat;
			width: 23px;
			height: 23px;
			margin: -2px 0px 0px 10px;
			padding-right: 25px;
			padding-bottom: 2px;
		}
	}
	.footer-item-content {
		display: none;
		padding: 15px 0px;
		background-color: #e6e7e8;
		border-bottom: solid 1px darken(#d1d2d4, 15%);
		ul li {
			list-style: none;
			padding: 5px 15px;
			a {
				font-family: @mainFont;
			}
			a:link {
				color: #404041;
				.fa-stack {
					color: #404041;
				}
			}
			a:visited {
				color: #404041;
				.fa-stack {
					color: #404041;
				}
			}
			a:hover {
				color: #404041;
				.fa-stack {
					color: #404041;
				}
			}
		}
		ul.social-media {
			margin-top: 15px;
		}
		h6 {
			margin: 0 15px 15px;
		}
		p {
			margin: 15px;
			color: #404041;
		}
		table {
			margin-left: 15px;
			tr {
				td {
					font-family: @mainFont;
					padding-right: 10px;
					color: #404041;
					font-size: 13px;
				}
			}
		}
	}
	.footer-selected {
		border-bottom: none;
		padding-bottom: 0px;
		h6 {
			border-bottom: solid 1px darken(#d1d2d4, 15%);
			padding-bottom: 10px;
		}
	}
	.footer-item-default {
		display: block;
	}
	.footer-item:last-child {
		margin-bottom: 0px;
		.category-item-head {
			border-bottom: none;
		}
	}
}


/* Small devices (tablets, 768px and up) */
@media (min-width: @screen-sm-min) {
	footer {
		position: absolute;
		
	}
	.clear-footer {
		height: 488px;
	}
	.notion {
		margin-bottom: 0px;
		padding: 40px 0;
	}
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: @screen-md-min) {
	.clear-footer {
		height: 374px;
	}
}
/* Large devices (large desktops, 1200px and up) */
@media (min-width: @screen-lg-min) {
	.clear-footer {
		height: 344px;
	}
}