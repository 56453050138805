.site-nav-mobile {
	position: relative;
	float: left;
	margin-top: 0px;
	z-index: 110;
	.btn {
		margin-top: 10px;
		margin-bottom: 0;
		padding: 6px 10px;
		font-size: 15px;
		background-color: #253E8D;
	}
	a.btn {
		font-family: @mainFont;
		color: @white;
	}
}

nav.slideout-menu {
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	z-index: 0;
	width: 256px;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	display: none;
	background-color: #253E8D;
	ul li {
		padding: 8px 15px;
		border-top: solid 1px lighten(#253E8D, 10%);
		border-bottom: solid 1px darken(#253E8D, 10%);
		color: @white;
		.clear-list;
		a:link {
			.white-link;
		}
		a:visited {
			.white-link;
		}
		a:hover {
			.white-link-hover;
		}
		.fa {
			margin-top: 3px;
			float: right;
		}
		.fa-negative {
			margin-top: -6px;
		}
		ul {
			margin-top: 5px;
			li {
				padding: 4px 15px;
				border-top: none;
				border-bottom: none;
				.fa {
					float: left;
					margin-right: 10px;
				}
			}
		}
	}
}

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
  overflow: hidden;
}

.slideout-open .slideout-menu {
  display: block;
}

.slideout-panel {
	position:relative;
	z-index: 1;
	background-color: @bodyColor;
	.box-shadow (-1px, 0px, 2px, 0.3);
}

.no-click {
	cursor: default;
}

.language-mobile {
	margin-top: 6px;
	.fa {
		color: @white;
	}
	a:link, a:visited, a:active, a:hover {
		color: @white;
	}
}

/* Small devices (tablets, 768px and up) */
@media (min-width: @screen-sm-min) {
	.slideout-panel {
		.box-shadow (0px, 0px, 0px, 0) !important;
	}
	
	.site-nav {
		background-color: #253E8D;
		margin-left: 0;
		float: left;
		ul {
			li {
				float: left;
				list-style: none;
				a {
					display: block;
					font-size: 12px;
					font-family: @mainFont;
					padding: 10px 7px;
					
				}
				a:link, a:active, a:visited, a:hover {
					color: @white;
					text-decoration: none;
				}
				ul.site-nav-child {
					display: none;
				}
			}
			li:hover {
				a {
					background-color: darken(#253E8D,15%);
				}
			}
			li.active {
				a {
					background-color: darken(#253E8D,15%);
				}
			}
			li.has-child:hover {
				ul.site-nav-child {
					margin-top: 0px;
					background-color: lighten(@black,10%);
					display: block;
					position: absolute;
					li {
						float: none;
						border-bottom: solid 1px darken(#253E8D,10%);
						ul.site-nav-child {
							display: none;
						}
						a {
							background-color: darken(#253E8D,5%);
							padding: 10px 15px;
							letter-spacing: 0px;
							text-transform: none;
						}
						a:after {
							display: none;
						}
					}
					li:hover {
						a {
							background-color: darken(#253E8D,10%);
						}
					}
					li:last-child {
						border-bottom: none;
					}
					li.has-child:hover {
						ul.site-nav-child {
							margin-top: -42px;
							background-color: darken(@white,5%);
							display: block;
							position: absolute;
							left: 100%;
							li {
								border-left: solid 1px darken(#253E8D,10%);
								border-bottom: solid 1px darken(#253E8D,10%);
								background-color: #253E8D;
								width: 100%;
								a {
									background-color: #253E8D;
									text-transform: none;
									white-space: nowrap;
									width: auto;
									height: auto;
								}
								a:after {
									display: none;
								}
							}
							li:hover {
								background-color: darken(@white,10%);
								a {
									background-color: darken(#253E8D,10%);
								}
							}
						}
					}
				}
			}
		}
	}
	
	.site-nav::after {
		content: "";
		width: 2000px;
		background-color: #253E8D;
		position: absolute;
		height: 41px;
	}
	.site-nav::before {
		content: "";
		position: absolute;
		width: 0;
		height: 0;
		margin-left: -41px;
		border-style: solid;
		border-width: 0 0 41px 41px;
		border-color: transparent transparent #253E8D transparent;
	}
	
	.social-media {
		left: auto;
		right: 0;
		top: 0;
		font-family: @mainFont;
		background-color: @grey;
		padding: 6px 10px 5px;
		color: @white;
		float: right;
		margin-right: 0;
		a {
			.fa-inverse {
				color: @grey;
			}
		}
	}
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: @screen-md-min) {
	.site-nav {
		ul {
			li {
				a {
					font-size: 14px;
					padding: 12px 20px;
				}
			}
		}
	}
	
	.site-nav::after {
		height: 45px;
	}
	.site-nav::before {
		margin-left: -45px;
		border-width: 0 0 45px 45px;
	}
	
	.social-media {
		padding: 11px 15px 12px;
	}
}
/* Large devices (large desktops, 1200px and up) */
@media (min-width: @screen-lg-min) {
	.site-nav {
		ul {
			li {
				a {
					font-size: 18px;
					padding: 12px 20px;
				}
			}
		}
	}
}


