/* LESS Document */
.gradient (@startColor: #00759a, @endColor: #f2f2f2) {
	background-color: @startColor;
	background: -webkit-gradient(linear, left top, left bottom, from(@startColor), to(@endColor));
	background: -webkit-linear-gradient(top, @startColor, @endColor);
	background: -moz-linear-gradient(top, @startColor, @endColor);
	background: -ms-linear-gradient(top, @startColor, @endColor);
	background: -o-linear-gradient(top, @startColor, @endColor);
}
.img-responsive {
	width: 100%;
}

/* Box Shadow */
.box-shadow (@x: 1px, @y: 1px, @blur: 1px, @alpha: 0.5) {
	-webkit-box-shadow: @x @y @blur rgba(0, 0, 0, @alpha);
	-moz-box-shadow: @x @y @blur rgba(0, 0, 0, @alpha);
	box-shadow: @x @y @blur rgba(0, 0, 0, @alpha);
}

/* Rounded Corners */
.rounded-equal (@rounded: 4px) {
	-webkit-border-radius: @rounded;
    -moz-border-radius: @rounded;
    border-radius: @rounded;
}


/* White Link */
.white-link {
	color: @white;
	text-decoration: none;
}
.white-link-hover {
	color: darken(@white,10%);
	text-decoration: none;
}

/* Black Link */
.black-link {
	color: @black;
	text-decoration: none;
}
.black-link-hover {
	color: lighten(@black,10%);
	text-decoration: none;
}

/* Clear list */
.clear-list {
	list-style: none;
}

/* Transparancy */
.transparancy (@alpha: 0.5) {
	@alpha-ie: @alpha * 100;
	opacity: @alpha;
    filter: alpha(opacity=@alpha-ie);
}

/* Border Bottom */
.border-default (@pb: 15px, @mb: 15px, @colorvalue: 10%) {
	border-bottom: solid 1px lighten(@ausGrey, @colorvalue);
	padding-bottom: @pb;
	margin-bottom: @mb;
}