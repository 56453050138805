* {
  margin: 0;
  padding: 0;
}

/*Typography Style*/
li,p,a, h1, h2, h3, h4, h5, h6 {
	font-family: @mainFont;
}
li strong, p strong, a strong, h1 strong, h2 strong, h3 strong, h4 strong, h5 strong, h6 strong {
	font-family: @mainFont-bold;
	font-weight: bold;
}

h1 {
	font-size: @font-size-h1 - 10;
	line-height: floor((@font-size-h1 * @line-height-base) - 15);
}
h2 {
	font-size: @font-size-h2 - 10;
	line-height: floor((@font-size-h2 * @line-height-base) - 10);
}
h3 {
	font-size: @font-size-h3 - 8;
	line-height: floor((@font-size-h3 * @line-height-base) - 10);
}
h4 {
	font-size: @font-size-h4 - 5;
	line-height: floor((@font-size-h4 * @line-height-base) - 5);
}
h5 {
	font-size: @font-size-h5 - 2;
	line-height: floor((@font-size-h5 * @line-height-base) - 2);
}
h6 {
	font-size: @font-size-h6;
	line-height: floor((@font-size-h6 * @line-height-base));
}
h1, h2, h3, h4, h5, h6 {
	margin-bottom: 15px;
}
p {
	font-size: @font-size-base - 2;
	line-height: @line-height-computed - 2;
	margin-bottom: 15px;
}
ul,
ol {
	margin-top: 0;
	margin-bottom: 0;
	ul,
	ol {
		margin-bottom: 0;
	}
}
ul li, ol li {
	font-size: @font-size-base - 2;
	line-height: @line-height-computed - 2;
	margin-bottom: 0px;
}

a:link {
	color: @blue;
}
a:active {
	color: @blue;
}
a:visited {
	color: @blue;
}
a:hover {
	color: darken(@blue, 15%);
	text-decoration: none;
}

/*Text Color*/

/*Background Color*/
.background-grey {
	background-color: darken(@white, 20%);
}

/*Button*/
.btn {
	background-color: #58585B;
	padding: 10px 15px;
	border-radius: 0;
	text-transform: uppercase;
	margin: 15px 0;
	font-size: 13px;
	color: @white;
}
a.btn:link {
	color: @white;
}
a.btn:active {
	color: @white;
}
a.btn:visited {
	color: @white;
}
a.btn:hover {
	color: @white;
	background-color: darken(@blue, 20%);
}

.btn-red {
	background-color: @red;
}
a.btn-red:link {
	color: @white;
}
a.btn-red:visited {
	color: @white;
}
a.btn-red:hover {
	color: @white;
	background-color: darken(@red, 20%);
}

.btn-grey {
	background-color: @grey;
}
a.btn-grey:link {
	color: @white;
}
a.btn-grey:visited {
	color: @white;
}
a.btn-grey:hover {
	color: @white;
	background-color: darken(@grey, 20%);
}

.btn-small {
	padding: 5px 8px;
	font-size: 12px;
}

html {
	height: 100%;
}
body {
	height: 100%;
	background-color: @bodyColor;
	color: @darkGrey;
}
.site-wrapper {
	min-height: 100%;
	width: @fullWidth;
	overflow: hidden;
}
.site-wrapper-background {
	width: 100%;
	height: 600px;
	position: absolute;
	z-index: -1;
	.gradient;
}
.site-content {
	background-color: #ddd;
	margin-bottom: 30px;
}
section#home {
	margin-top: 82px;
	position: relative;
	z-index: 0;
}

/*Section Setting*/
.inner-padding {
	padding: 30px 0;
}



/*Modal Picture*/
.modal {
	.modal-content {
		.modal-body {
			img {
				max-width: 100%;
				margin-bottom: 15px;
			}
		}
	}
}


/*Smart Forms*/
.smart-forms {
	margin-top: 0px; 
	label {
		font-family: @mainFont;
	}
	.section {
		margin-bottom: 0px;
	}
	.select > select::-ms-expand { 
		display: none; 
	}
	.select {
		.arrow2 {
			position: absolute;
			top: 9px;
			right: 4px;
			width: 24px;
			height: 24px;
			color:#9F9F9F;
			pointer-events:none;
			z-index:16;
		}
		.arrow2:after, .arrow2:before {
			content: '';
			position: absolute;
			font: 12px "Consolas", monospace;
			font-style: normal;
			pointer-events: none;
			display: none\9;
			left: 5px;
		}
		.arrow2:before { content:'\25BC'; bottom:4px; }
		.double:after { content:'\25B2'; top:-1px;  }
		.double:before { content:'\25BC'; bottom:-1px; }
	}
	.gui-textarea, .gui-textarea:focus {
		height: 199px !important;
	}
	button {
		margin-top: 15px;
		float: right;
		color: #fff;
	}
}

/*Pagination*/
.pagination {
	margin: 15px 0;
	float: right;
	ul {
		li {
			list-style: none;
			float: left;
			margin-right: 10px;
			margin-bottom: 10px;
			padding: 5px;
			a:link, a:visited {
				color: @darkGrey;
			}
			a:hover {
				color: lighten(@darkGrey, 10%);
			}
		}
		li.active {
			padding: 5px 10px;
			background-color: @grey;
			a:link, a:visited, a:hover {
				color: @white;
			}
		}
	}
}

/* Small devices (tablets, 768px and up) */
@media (min-width: @screen-sm-min) {
	h1 {
		font-size: @font-size-h1 ;
		line-height: floor((@font-size-h1 * @line-height-base) - 10) ;
	}
	h2 {
		font-size: @font-size-h2 ;
		line-height: floor((@font-size-h2 * @line-height-base)) ;
	}
	h3 {
		font-size: @font-size-h3 ;
		line-height: floor((@font-size-h3 * @line-height-base)) ;
	}
	h4 {
		font-size: @font-size-h4 ;
		line-height: floor((@font-size-h4 * @line-height-base)) ;
	}
	h5 {
		font-size: @font-size-h5 ;
		line-height: floor((@font-size-h5 * @line-height-base)) ;
	}
	h6 {
		font-size: @font-size-h6 ;
		line-height: floor((@font-size-h6 * @line-height-base)) ;
	}
	h1, h2, h3, h4, h5, h6 {
		margin-bottom: 20px ;
	}
	p {
		font-size: @font-size-base ;
		line-height: 28px ;
		margin-bottom: 20px ;
	}
	ul li, ol li {
		font-size: @font-size-base ;
		line-height: @line-height-computed ;
	}
	
	.btn {
		font-size: 15px;
	}
	
	.sidebar {
		margin-top: 0px;
	}
	
}
/* Medium devices (desktops, 992px and up) */
@media (min-width: @screen-md-min) {
}
/* Large devices (large desktops, 1200px and up) */
@media (min-width: @screen-lg-min) {
}










/*---------------------------------------Slider---------------------------------------*/
.slide-container {
	.slide {
		.owl-theme .owl-dots {
			position: absolute;
			bottom: 40px;
			right: 15px;
		}
		.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
			background: @blue;
		}
	}
}

/* Small devices (tablets, 768px and up) */
@media (min-width: @screen-sm-min) {
	.slide-container {
		padding-right: 0;
	}
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: @screen-md-min) {
	
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: @screen-lg-min) {
	
}



/*---------------------------------------Section Title---------------------------------------*/
.section-title {
	border-bottom: solid 1px #a7a9ab;
	margin-bottom: 15px;
	padding-bottom: 7px;
	h4 {
		display: inline;
		border-bottom: solid 2px @blue;
		padding-bottom: 7px;
	}
}

/* Small devices (tablets, 768px and up) */
@media (min-width: @screen-sm-min) {
	
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: @screen-md-min) {
	
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: @screen-lg-min) {
	
}





/*---------------------------------------Home Category---------------------------------------*/
.home-category-container {
	margin-bottom: 15px;
}
.home-category-menu {
	padding: 0;
	margin-top: -15px;
	background-color: #e6e7e8;
	ul {
		li {
			list-style: none;
			border-bottom: solid 1px #a7a9ab;
			a {
				display: block;
				padding: 8px 10px;
			}
			a:link, a:active, a:visited {
				color: @black;
				background-color: #e6e7e8;
			}
			a:hover {
				color: @black;
				background-color: @white;
			}
		}
		li:last-child {
			border-bottom: none;
		}
	}
}

/* Small devices (tablets, 768px and up) */
@media (min-width: @screen-sm-min) {
	.home-category-container {
		padding-left: 0;
		margin-bottom: 0;
	}
	.home-category-menu {
		padding: 15px;
		min-height: 339px;
		margin-top: 0;
	}
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: @screen-md-min) {
	
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: @screen-lg-min) {
	
}



/*---------------------------------------Product List---------------------------------------*/

.new-product {
	overflow: auto;
	margin-bottom: 30px;
}
.product-list-home {
	ul {
		li {
			width: 100%;
		}
	}
}
.product-list-main {
	margin-top: 30px;
	ul {
		li {
			width: 100%;
		}
	}
}
.product-list {
	ul {
		li {
			float: left;
			list-style: none;
			padding: 0 15px 15px;
			margin-bottom: 15px;
			border-bottom: solid 1px #d1d2d4;
			h6.product-title {
				margin-top: 0;
				font-size: 15px;
				color: #1b75bb;
				line-height: 18px;
				min-height: 60px;
				font-weight: bold;
				margin-bottom: 0;
				span.product-category {
					font-size: 12px;
					color: #404041;
					font-weight: 400;
				}
			}
			img.product-thumb {
				width: 100%;
			}
			h6.product-price {
				color: #404041;
				font-weight: bold;
				margin-bottom: 0;
			}
			p.product-link {
				text-align: center;
				img {
					margin: 0 3px;
				}
			}
			a.btn {
				width: 100%;
				padding: 5px 15px;
			}
		}
		li:last-child {
			border-bottom: none;
		}
	}
}

.home-brand {
	overflow: auto;
	margin-bottom: 30px;
}

.product-sort {
	overflow: auto;
	margin-top: 10px;
	margin-bottom: 10px;
	ul {
		li {
			list-style: none;
			a:link, a:active, a:visited {
				color: #4063ae;
			}
			a:hover {
				color: #4063ae;
				text-decoration: underline;
			}
		}
	}
}

/* Small devices (tablets, 768px and up) */
@media (min-width: @screen-sm-min) {
	.product-list-home {
		ul {
			li {
				width: 20%;
			}
			li:nth-child(5n+1) {
				border-left: none;
			}
		}
	}
	.product-list-main {
		ul {
			li {
				width: 25%;
			}
			li:nth-child(4n+1) {
				border-left: none;
				clear: both;
			}
		}
	}
	.product-list {
		ul {
			li {
				border-left: solid 1px #d1d2d4;
				border-bottom: none;
				padding: 0 15px;
				margin-bottom: 0;
			}
			
		}
	}
	.product-sort {
		ul {
			float: right;
			li {
				float: left;
				padding-right: 15px;
				margin-right: 15px;
				border-right: solid 1px #404041;
			}
			li:last-child {
				border-right: none;
				padding-right: 0;
				margin-right: 0;
			}
		}
	}
	
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: @screen-md-min) {
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: @screen-lg-min) {
	
}





/*---------------------------------------Page Title & Breadcrumbs---------------------------------------*/

.breadcrumb {
	background-color: #f1f1f2;
	border-radius: 0;
	margin-bottom: 0;
	margin-top: -1px;
	padding: 5px 15px;
	border-top: solid 2px #a7a9ab;
	ul {
		margin: 0px 0 0 0;
		a:link, a:active, a:visited {
			color: @white;
		}
		a:hover {
			color: darken(@white,10%);
		}
		li {
			list-style: none;
			float: left;
			.fa-angle-right {
				margin: 0 20px;
				font-size: 11px;
				color: @white;
			}
			color: @white;
		}
	}
}

section.page-title {
	position: relative;
	width: 100%;
	padding: 40px 0;
	background-image: url(../images/title-bg.png);
	background-repeat: repeat-x;
	background-position: left bottom;
	h2 {
		letter-spacing: 10px;
		text-align: center;
		margin: 0;
	}
}


/* Small devices (tablets, 768px and up) */
@media (min-width: @screen-sm-min) {
	section.breadcrumb {
		hr {
			display: block;
		}
	}
}







/*---------------------------------------Web Content---------------------------------------*/
.web-content {
	h2 {
		color: #253E8D;
	}
	h3 {
		margin-top: 40px;
	}
	h3:first-child {
		margin-top: 0;
	}
	h4, p {
		color: #404041;
	}
	h4.highlight, h5.highlight, h6.highlight {
		font-weight: 700;
		color: #253E8D;
	}
	.blue {
		color: @blue;
	}
	.title {
		h2 {
			margin-top: 0;
		}
	}
	img {
		max-width: 100%;
		margin-bottom: 30px;
	}
	padding: 0 0 30px 0;
	hr {
		border-top: solid 2px @lightGrey;
		margin-bottom: 15px;
	}
	table {
		font-size: 15px;
		font-family: @mainFont;
		margin-bottom: 30px;
	}
	.dropdown button {
		margin-right: 15px;
		margin-top: 15px;
		margin-bottom: 15px;
		padding: 5px 15px;
		border: solid 1px @black;
		border-radius: 0;
	}
}

.side-menu {
	margin-bottom: 30px;
	h6 {
		margin: 0;
		padding: 3px 15px;
		background-color: #f1f1f2;
		color: @blue;
		border-bottom: solid 2px #a7a9ab;
		font-weight: bold;
	}
	ul {
		li {
			list-style: none;
			border-bottom: solid 1px #d1d2d4;
			padding: 5px 15px;
			a:link, a:active, a:visited {
				color: #404041;
			}
			a:hover {
				color: lighten(#404041, 15%);
			}
		}
	}
	
}

.faq {
	ol {
		margin-left: 30px;
	}
}

.product-container {
	margin-top: 30px;
}
.product-detail {
	h4 {
		color: #1b75bb;
		margin-bottom: 0;
	}
	h5 {
		margin-bottom: 10px;
	}
	h5.blue {
		color: @blue;
	}
	p.product-link {
		img {
			margin: 0 3px;
		}
	}
	.table-container {
		width: 100%;
		padding: 15px;
		background-color: #E6E7E8;
		margin-bottom: 30px;
		table {
			margin-bottom: 0;
			tr {
				td {
					padding-right: 30px;
					padding-bottom: 5px;
				}
				td:nth-child(2) {
					color: #253E8D;
				}
			}
			tr:last-child {
				td {
					padding-bottom: 0;
				}
			}
		}
	}
	.btn {
		background-color: #58585B;
		border-radius: 5px;
		margin-right: 15px;
		margin-bottom: 15px;
		margin-top: 0;
	}
	.btn-blue {
		background-color: #253E8D;
	}
}

.product-image {
	margin-bottom: 30px;
	.glass-case {
		width: 100% !important;
		.gc-display-area {
			border-radius: 0;
			border: solid 1px @grey;
			.gc-display-container {
				margin-left: auto !important;
				margin-right: auto !important;
				margin-top: 0px !important;
				top: auto !important;
				left: auto !important;
			}
			.gc-icon-next, .gc-icon-prev {
				display: block !important;
			}
		}
		.gc-thumbs-area {
		}
		.gc-zoom {
			margin-left: 30px !important;
		}
	}
}

.related-product {
	text-align: center;
	margin-top: 30px;
}

.image-folder {
	margin-top: 30px;
}
.image-folder-thumb {
    border: solid 1px #353535;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    margin-bottom: 10px;
	img {
		max-width: 100%;
	}
}

.modal-body {
	img {
		width: 100%;
	}
}

.sitemap {
	ul {
		li {
			list-style: none;
			a:link, a:active, a:visited {
				color: @black;
				text-decoration: underline;
			}
			a:hover {
				color: @black;
				text-decoration: none;
			}
			ul {
				li {
					.fa {
						font-size: 12px;
					}
				}
			}
		}
	}
}

/* Small devices (tablets, 768px and up) */
@media (min-width: @screen-sm-min) {
	.web-content {
		padding: 40px 0 60px;
		h2 {
			margin: 0;
			display: inline;
			border-bottom: solid 2px @blue;
		}
		h5 {
			font-size: 18px;
			line-height: 28px;
		}
		.intro {
			margin-top: 30px;
		}
	}
	.product-image {
		margin-bottom: 30px;
		.glass-case {
			.gc-display-area {
			}
			.gc-thumbs-area {
			}
		}
		
	}

}

/* Medium devices (desktops, 992px and up) */
@media (min-width: @screen-md-min) {
	.product-image {
		margin-bottom: 15px;
		.glass-case {
			.gc-display-area {
			}
			.gc-thumbs-area {
			}
		}
		
	}
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: @screen-lg-min) {
	.product-image {
		.glass-case {
			.gc-display-area {
			}
			.gc-thumbs-area {
			}
		}
	}
}


/*---------------------------------------About---------------------------------------*/
.about {
	background-image: url(../images/map.png);
	background-position: right top 72%;
	background-repeat: no-repeat;
	background-size: 160%;
	p.p-right {
		margin-top: 250px;
	}
}



.visi {
	width: 100%;
	position: relative;
	margin-top: 40px;
	margin-bottom: 140px;
	color: @white;
	background-color: #2E7CBB;
	padding: 40px 0 0;
	h2 {
		background-color: #253E8D;
		padding: 10px 30px;
		display: inline-block;
	}
	h2::after {
		content: "";
		position: absolute;
		width: 0;
		height: 0;
		top: 21px;
		margin-left: 30px;
		border-style: solid;
		border-width: 30px 0 30px 40px;
		border-color: transparent transparent transparent #253E8D;
	}
	ul {
		margin-top: 15px;
		margin-left: 30px;
		li {
			font-size: 18px;
			line-height: 26px;
		}
	}
	
}
.visi::before {
	content: "";
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 0 80px 3000px;
	border-color: transparent transparent #2E7CBB transparent;
	position: absolute;
	top: -80px;
}
.visi::after {
	content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 80px 3000px 0 0;
    border-color: #2E7CBB transparent transparent transparent;
    position: absolute;
    bottom: -80px;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: @screen-sm-min) {
	.about {
		background-position: right top 35%;
		background-size: 80%;
		p.p-right {
			margin-top: 611px;
		}
	}
	
	.visi {
		h2::after {
			border-width: 35px 0 35px 45px;
		}
	}
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: @screen-md-min) {
	.about {
		background-position: right top 20%;
		background-size: 80%;
		p.p-right {
			margin-top: 454px;
		}
	}

}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: @screen-lg-min) {
	.about {
		background-position: right top;
		background-size: auto;
		p.p-right {
			margin-top: 389px;
		}
	}
}


/*---------------------------------------Our Team---------------------------------------*/
.our-team {
	margin-top: 30px;
	img.team-photo {
		border: solid 1px @grey;
		-webkit-box-shadow: 10px 10px 0px 0px rgba(230,230,230,1);
		-moz-box-shadow: 10px 10px 0px 0px rgba(230,230,230,1);
		box-shadow: 10px 10px 0px 0px rgba(230,230,230,1);
		max-width: 90%;
	}
	h3 {
		margin-bottom: 0;
	}
	h6 {
		margin-top: 0;
		margin-bottom: 0;
		color: #6D6E70;
		font-style: italic;
	}
	hr.title {
		width: 150px;
		margin: 20px 0;
	}
	hr {
		margin: 30px 0;
	}
}